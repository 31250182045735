import ApiService from "@/core/services/api.service";

export const SET_LISTA = "setList";

const actions = {
    async all(context, value) {
        await ApiService.get('departamento')
            .then(response => context.commit(SET_LISTA, response.data.departamentos))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async create(context, value) {
        let message = await ApiService.post('curso/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update(context, value) {
        let message = await ApiService.put('curso/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async delete(context, value) {
        let message = await ApiService.get('curso/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
};

const getters = {};

const mutations = {

    [SET_LISTA](state, value) {
        state.lista = value
    },

};

const state = {
    lista: [],
    mensagem_alert: ''
};


export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
};