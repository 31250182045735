import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import ("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import ("@/view/pages/Dashboard.vue"),
                    meta: {title: 'Dashboard'}
                },

                // Empresas

                {
                    path: "/empresas",
                    name: "empresas",
                    component: () =>
                        import ("@/view/components/empresas/listar.vue")
                },

                {
                    path: "/createEmpresa",
                    name: "createEmpresa",
                    component: () =>
                        import ("@/view/components/empresas/create.vue")
                },

                // Usuarios

                {
                    path: "/usuarios",
                    name: "usuario",
                    component: () =>
                        import ("@/view/components/usuarios/listar.vue")
                },

                {
                    path: "/createUsuario",
                    name: "createUsuario",
                    component: () =>
                        import ("@/view/components/usuarios/create.vue")
                },

                // Departamentos

                {
                    path: "/departamentos",
                    name: "departamentos",
                    component: () =>
                        import ("@/view/components/departamentos/listar.vue")
                },

                {
                    path: "/createDepartamento",
                    name: "createDepartamento",
                    component: () =>
                        import ("@/view/components/departamentos/create.vue")
                },

                // Chatbots
                
                {
                    path: "/chatbots",
                    name: "chatbots",
                    component: () =>
                        import ("@/view/components/chatbots/listar.vue")
                },

                {
                    path: "/createChatbots",
                    name: "createChatbots",
                    component: () =>
                        import ("@/view/components/chatbots/create.vue")
                },
                {
                    path: "/editarChatbot",
                    name: "editarChatbot",
                    component: () =>
                        import ("@/view/components/chatbots/editar.vue")
                },
                    
            ]
        },

        {
            path: "/",
            component: () => import ("@/view/pages/auth/Login"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import ("@/view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () =>
                        import ("@/view/pages/auth/Login")
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            path: "/404",
            name: "404",
            component: () => import ("@/view/pages/error/Error-1.vue"),
        },

    ]
});