import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from "@/core/services/jwt.service";
import FilialService from "@/core/services/filial.service";
import {LOGOUT} from "@/core/store/auth.module";
import Swal from "sweetalert2";
import store from "@/core/store/index";
import router from "@/core/router/router";

let isRefreshing = false;
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios)
        Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL

        Vue.axios.interceptors.request.use(config => {
            config.headers['access-token'] = `${JwtService.getToken()}`
            config.headers['api-key']='21a87771-a92c-44c3-b44f-546aa5221234'
            return config
        })

        Vue.axios.interceptors.response.use(
            successResponse => {

                let tokenTTL = JwtService.getTokenTimingLeft()
                if (tokenTTL && (tokenTTL / (1000 * 60)) < 30 && isRefreshing === false) {
                    isRefreshing = true

                    axios.create({
                        baseURL: process.env.VUE_APP_API_URL,
                        headers: {'Authorization': `Bearer ${JwtService.getToken()}`}
                    }).post(`auth/refresh`)
                        .then(({data}) => JwtService.saveToken(data.access_token))
                        .finally(() => isRefreshing = false)
                }

                return successResponse
            },
            error => {
                if (error.response.status === 401 && error.response.data !== 'unauthorized') {
                    store.dispatch(LOGOUT)
                    router.push({name: 'login'})
                    Swal.fire({
                        title: 'Realize login novamente!', icon: 'error'
                    })
                }
                return Promise.reject(error)
            }
        );
    },

    setHeader() {
        Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
        Vue.axios.defaults.headers.common["filial"] = FilialService.getFilialId();
    },

    setHeaderFilial() {
        Vue.axios.defaults.headers.common["filial"] = FilialService.getFilialId();
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    get(resource, params) {
        return Vue.axios.get(`${resource}`, params).catch(error => {
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params, config) {
        return Vue.axios.post(resource, params, config);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */

    patch(resource,params) {
        return Vue.axios.patch(`${resource}`, params);
    }
}; 


export default ApiService;