import ApiService from "@/core/services/api.service";

export const SET_LISTA_USUARIOS = "setListUsuarios";
export const SET_USUARIO  = "setUsuario";
export const SET_MESSAGE_ALERT = "setMensagem";

const actions = {
    async all(context, value) {
        await ApiService.get('usuario')
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data.usuarios))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response
            }))
    },
    async get(context, value) {
        await ApiService.get('usuario/'+value.id)
            .then(response => context.commit(SET_USUARIO, response.data.usuario))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response
            }))
    },
    async create(context, value) {
        let message = await ApiService.post('usuario', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
    async update(context, value) {
        let message = await ApiService.put('curso/atualizar/' + value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async delete(context, value) {
        let message = await ApiService.get('curso/bloquear/' + value.id)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit('SET_MESSAGE_ALERT', message)
    },

    async lista_departamento_usuario(context, value) {
        await ApiService.get('usuario/departamento')
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))

            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error', message: error.response.data
            }))
    },
    async create_departamento_atendente(context, value) {
        // console.log(value)

        // var dados = {"id":11,"departamentos":[1,2]};
        let message = await ApiService.patch('usuario/departamentos', value)
            .then(response => ({tipo: 'success', message: "Adicionado com sucesso"}))
            .catch((error) => ({tipo: 'error', message: error.response}))
        context.commit('SET_MESSAGE_ALERT', message)
    },
};

const getters = {};

const mutations = {

    [SET_LISTA_USUARIOS](state, value) {
        state.lista = value
    },
    [SET_USUARIO](state, value) {
        state.usuario = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        state.mensagem_alert = value
    },

};

const state = {
    lista: [],
    mensagem_alert: '',
    usuario:{}
};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
};