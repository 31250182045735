import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";



//modulos sistema
import configEmpresa from "./vuex/modulos/configEmpresa"
import empresas from "./vuex/modulos/empresas";
import usuarios from "./vuex/modulos/usuarios"
import departamentos from "./vuex/modulos/departamentos"
import chatbots from "./vuex/modulos/chatbots"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
    
        //moodulos sistema
        configEmpresa,
        empresas,
        usuarios,
        departamentos,
        chatbots

    }
});