import ApiService from "@/core/services/api.service";

export const SET_LISTA = "setList";
export const SET_USUARIO = "setUsuario";
export const SET_MESSAGE_ALERT = "setMensagem";
export const SET_ARRAY_NOS = "setArryNos";
export const SET_BOT = "setBot";
export const SET_ADICIONAR_NO = "setAdicionarNo";
export const SET_REMOVER_NO = "setRemoverNo";
export const SET_TELEFONES = "setTelefones";
export const SET_QRCODE = "setQrcode";

const actions = {
  async all(context, value) {
    await ApiService.get("chatbot")
      .then((response) => context.commit(SET_LISTA, response.data.chatbots))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response,
        })
      );
  },
  async get(context, value) {
    // console.log('aqui')
    await ApiService.get("chatbot/" + value.id)
      .then((response) => {
        //  console.log(response.data.chatbot.nos)
        context.commit(SET_BOT, response.data.chatbot);
        // context.commit(SET_ARRAY_NOS, response.data.chatbot.nos)
      })
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response,
        })
      );
  },
  async create(context, value) {
    console.log(value);
    let message = await ApiService.post("chatbot", value)
      .then((response) => ({ tipo: "success", message: response.data }))
      .catch((error) => ({ tipo: "error", message: error.response.data }));
    context.commit("SET_MESSAGE_ALERT", message);
  },
  async allTelefones(context, value) {
    await ApiService.get("celular")
      .then((response) =>
        context.commit(SET_TELEFONES, response.data.celulares)
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response,
        })
      );
  },
  async celulares(context, value) {
      console.log(value)
    await ApiService.patch("chatbot/celulares",value)
      .then((response) =>
        console.log(response)
      )
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response,
        })
      );
  },
  async venon_qrcode(context, value) {
    await ApiService.get("venom/chatbot/"+value.bot_id+"/celular/"+ value.numero_id +"/qrcode")
      .then((response) => context.commit(SET_QRCODE, response.data))
      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response,
        })
      );
  },
  async update(context, value) {
    //  console.log(value);
    // delete value.usuario;

    for (var i in value.nos) {
      var c = [];
      var o = "";
      // delete value.nos[i].porcentagem;

      for (var b in value.nos[i].filhos) {
        o = {
          id: value.nos[i].filhos[b].id,
          sequencia: value.nos[i].filhos[b].sequencia,
          opcao: value.nos[i].filhos[b].opcao,
        };
        //  c.push(o)

        value.nos[i].filhos[b] = o;
      }

      if (!c.length) {
        value.nos[i].ultimo = true;
      }
    }
    console.log(value);

    let message = await ApiService.put("chatbot", value)
      .then((response) => ({ tipo: "success", message: response.data }))
      .catch((error) => ({ tipo: "error", message: error.response.data }));
    // context.commit("SET_MESSAGE_ALERT", message);
  },

  async delete(context, value) {
    let message = await ApiService.get("curso/bloquear/" + value.id)
      .then((response) => ({ tipo: "success", message: response.data }))
      .catch((error) => ({ tipo: "error", message: error.response.data }));
    context.commit("SET_MESSAGE_ALERT", message);
  },

  async lista_departamento_usuario(context, value) {
    await ApiService.get("usuario/departamento")
      .then((response) => context.commit(SET_LISTA_USUARIOS, response.data))

      .catch((error) =>
        context.commit("SET_MESSAGE_ALERT", {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async create_departamento_atendente(context, value) {
    let message = await ApiService.patch("usuario/departamentos", value)
      .then((response) => ({
        tipo: "success",
        message: "Adicionado com sucesso",
      }))
      .catch((error) => ({ tipo: "error", message: error.response }));
    context.commit("SET_MESSAGE_ALERT", message);
  },
  async array_nos(context, value) {
    context.commit(SET_ARRAY_NOS, value);
  },
  async adicionar_no(context, value) {
    context.commit(SET_ADICIONAR_NO, value);
  },
  async remover_no(context, value) {
    context.commit(SET_REMOVER_NO, value);
  },
};

const getters = {};

const mutations = {
  [SET_QRCODE](state, value) {
    state.qrcode = value;
  },
  [SET_TELEFONES](state, value) {
    state.telefones = value;
  },
  [SET_LISTA](state, value) {
    state.lista = value;
  },
  [SET_USUARIO](state, value) {
    state.usuario = value;
  },
  [SET_MESSAGE_ALERT](state, value) {
    state.mensagem_alert = value;
  },
  [SET_BOT](state, value) {
    state.bot = value;
  },
  [SET_ARRAY_NOS](state, value) {
    state.array_nos = value;
  },

  [SET_ADICIONAR_NO](state, value) {
    // console.log(state.bot.nos)

    for (var i in state.bot.nos) {
      if (state.bot.nos[i].sequencia == value.pai.sequencia) {
        state.bot.nos[i].filhos.push({
          id: "",
          sequencia: value.sequencia,
          opcao: parseInt(value.opcao),
        });
      }
    }
    state.bot.nos.push(value);
  },

  [SET_REMOVER_NO](state, value) {
    for (var i in state.bot.nos) {
      if (state.bot.nos[i].sequencia == value.pai.sequencia) {
        state.bot.nos[i].filhos.push({ id: "", sequencia: value.sequencia });
      }
    }

    // state.bot.nos.push(value);
  },
};

const state = {
  lista: [],
  mensagem_alert: "",
  usuario: {},
  array_nos: [],
  bot: "",
  telefones: [],
  qrcode:''
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
